import React, { useState } from 'react';
import { unitValue } from '../helpers/formatters';
import Chart from './Chart';
import ClickOutsideDetector from './ClickOutsideDetector';
import Trend from './Trend';

export default function Metric(props) {

  const [displayExpanded, setDisplayExpanded] = useState(false)
  const showExpanded = (props.expanded || displayExpanded)

  const displayClass = showExpanded ? "mado-metric-expanded" : ""
  const hasDataHistory = props.metricData.measurements.length && props.metricData.measurements.length > 1
  const goalCodeIcon = props.metricData.code ? `icon-goal_${props.metricData.code}` : ""
  const ytitle = props.metricData.y_label || props.metricData.unit || ""
  const expandClass = showExpanded ? 'icon-fullscreen-exit' : 'icon-stats-dots'

  const toggleExpandCollapse = () => {
    setDisplayExpanded(!displayExpanded)
  }


  const visible = !!!props.loading
  let value = unitValue(props.metricData.measurements, props.metricData.unit_size, props.metricData.precision)
  return(      
    <ClickOutsideDetector
      listen
      onClickOutside={() => { setDisplayExpanded(false) }}
    >
      <div className={`mado-metric ${displayClass} metric-code-${props.metricData.code}`}>
        <div className="mado-metric__header">
          <div>
            <div className={`mado-metric__header__icon ${goalCodeIcon}`}></div>
            <div className="mado-metric__header__heading">
              {visible && props.metricData.label}
            </div>
          </div>
          { !props.expanded &&
            <div className="mado-metric__header__expand">
              { visible && hasDataHistory &&
                <a onClick={toggleExpandCollapse}>
                  <span className={expandClass} />
                </a>
              }
            </div>
          }
        </div>
        { (!showExpanded || !hasDataHistory) &&
          <div className="mado-metric__primary">
            <div className="mado-metric__primary__value">
              {visible && props.metricData.prefix + value + props.metricData.suffix}
            </div>
            <div className="mado-metric__primary__unit">
              {visible && props.metricData.unit} &nbsp;
            </div>
          </div>
        }
        { showExpanded && hasDataHistory &&
          <div className="mado-metric__chart">
            <Chart data={props.metricData.measurements} ytitle={ytitle} max={props.metricData.suffix === "%" ? 100 : null} />
          </div>
        }
        <div className="mado-metric__goal">
          { props.metricData.goal && 
            <p>Goal:</p>
          }
          { visible && props.metricData.goal }
          { visible && props.metricData.description && 
            <p>
              { props.metricData.description }
            </p>
          }
        </div>

        { props.metricData.trend_goal && hasDataHistory &&
          <div className="mado-metric__trend">
            <Trend data={props.metricData.measurements} trendGoal={props.metricData.trend_goal} />
          </div>
        }

        <div className="mado-metric__footer">
          { visible && props.metricData.url &&
            <a href={props.metricData.url} className="mado-metric__button">Learn More</a>
          }
        </div>
      </div>
    </ClickOutsideDetector>
  )

}