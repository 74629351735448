import React from 'react';
import Dashboard from './Dashboard';
import MetricView from './MetricView';
import '../css/App.scss';

function App(props) {
  
  if (props.metric || props.metricCode) {
    return(
      <div className={`kt-dashboard-app ${props.report}`}>
        <MetricView {...props} />
      </div>
    )
  }

  return (
    <div className={`kt-dashboard-app ${props.report}`}>
      <Dashboard {...props} />
    </div>
  );
}

export default App;
